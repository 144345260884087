import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getAllVerifyResponse} from "../store/logIn";
import DownloadIcon from '@mui/icons-material/Download';
import Header from "../blocks/header";
import Dashboard from "../blocks/dashboard";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Box from "@mui/material/Box";
import Copyright from "../blocks/copyright";
import {useTranslation} from "react-i18next";
import ReactPaginate from "react-paginate";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteProblem from "../blocks/deleteProblem";
import {toast} from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {getAlLProblems, messageReset} from "../store/problems";
import VisibilityIcon from "@mui/icons-material/Visibility";

function Problems(props) {
    const is_main = JSON.parse(localStorage.getItem('is_main'));
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()
    const [problems, setProblems] = useState([])
    const [open, setOpen] = useState(false)
    const [openItem, setOpenItem] = useState(false)
    const params = useParams()
    const [regionId, setRegionId] = useState(false)
    useEffect(()=>{
        setRegionId(params.id)
    }, [params.id])
    const toBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        if (props.message === 'deleted') {
            toast.success(t('delete_success'), {theme: 'colored'})
            setOpenItem(false)
            props.messageReset()
        } else if (props.message === 'error') {
            toast.error(t('save_error'), {theme: 'colored'})
            props.messageReset()
        } else if (props.message === true) {
            toast.success(t('save_success'), {theme: 'colored'})
            props.messageReset()
        }
    }, [props.message])

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token !== 'undefined') {
            const data = {
                "token": JSON.parse(token)
            }
            props.getAllVerifyResponse(data)
        }
        props.getAlLProblems()
    }, [])

    useEffect(() => {
        if (props.verify === false) {
            navigate("/login/");
        }
        setProblems(props.problems)
    }, [props])

    function getFullDateTime(val){
        let newDate = new Date(val)
        let date = newDate.getDate();
        date = date<10 ? "0"+date : date
        let month = newDate.getMonth()+1;
        month = month<10 ? "0"+month : month
        let year = newDate.getFullYear();
        let hours = newDate.getHours();
        let minutes = newDate.getMinutes();
        let seconds = newDate.getSeconds();
        return date + "." + month + "." + year + "\t" + hours + ":" + minutes + ":" + seconds
    }


    // Pagination

    const [itemsPerPage, setItemsPerPage] = useState(10)

    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = problems.filter(item=>regionId ? item.user.id == regionId : item).slice(itemOffset, endOffset);
    const pageCount = Math.ceil(problems.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % problems.length;
        setItemOffset(newOffset);
    };

    if (pageCount !== 0 && currentItems.length === 0 && problems.filter(item=>regionId ? item.user.id == regionId : item).length !== 0) {
        let li_lenght = document.querySelectorAll('.pagination ul li').length
        document.querySelectorAll('.pagination ul li')[li_lenght - 2].previousSibling.className = 'selected'
        const data = {"selected": pageCount - 1}
        handlePageClick(data)
    }

    function toAdd() {
        navigate('/add-problem/')
    }

    function toView(item) {
        navigate('/view-problem/' + item.id)
    }

    function toEdit(data) {
        navigate('/edit-problem/' + data.id)
    }

    function toDelete(item) {
        setOpenItem(item)
        setOpen(true)
    }

    return <div>
        <div className={'page worker-page problems'}>
            {open ? <DeleteProblem setOpenItem={setOpenItem} openItem={openItem} open={open} setOpen={setOpen}/> : ''}
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                    <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={toBack}>
                        {t('back')}
                    </Button>
                    <div className={'header-page'}>
                        <h2 className={'page-title'}>{t('problems')}</h2>
                        {!is_main  ?
                            <Button variant="contained" title={t('add_problem')} endIcon={<AddIcon/>}
                                    onClick={toAdd}>
                                {t('add_problem')}
                            </Button>
                            : ''}
                    </div>
                    {currentItems.length ? <table className="table table-hover table-responsive ">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            {is_main ? <th scope="col">{t('title_company')}</th> : ''}
                            <th scope="col">{t('problem_title')}</th>
                            <th scope="col">{t('problem_date')}</th>
                            <th scope="col"/>
                        </tr>
                        </thead>
                        <tbody>
                        {currentItems.length ? currentItems.map((item, index) => <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                {is_main ? <td>{item.user.user_name ? item.user.user_name : 'ADMIN'}</td> : ''}
                                <td>{item.title}</td>
                                <td>{getFullDateTime(item.modified)}</td>
                                {!item.user.is_main ?
                                    <td>
                                        {is_main ? <Button variant="contained" title={"Ko'rish"} color={"primary"}
                                                           endIcon={<VisibilityIcon/>}
                                                           onClick={() => {
                                                               toView(item)
                                                           }}
                                        />: <Button variant="contained" title={"O'zgartirish"} color={"info"}
                                                    endIcon={<EditIcon/>}
                                                    onClick={() => toEdit(item)}/>}
                                        {is_main ?
                                            <Button variant="contained" title={"O'chirish"} color={"error"}
                                                    endIcon={<DeleteIcon/>}
                                                    onClick={() => {
                                                        toDelete(item)
                                                    }}
                                            />
                                            : <Button variant="contained" title={"O'chirish"} color={"error"}
                                                      endIcon={<DeleteIcon/>}
                                                      onClick={() => {
                                                          toDelete(item)
                                                      }}
                                            />}
                                    </td>
                                    : <td style={{minHeight:"42px"}}>
                                        {is_main && item.user.is_main ?
                                            <Button variant="contained" title={"O'chirish"} color={"error"}
                                                    endIcon={<DeleteIcon/>}
                                                    onClick={() => {
                                                        toDelete(item)
                                                    }}
                                            /> : ''}
                                    </td>
                                    }
                            </tr>)
                            :
                            ''
                        }
                        </tbody>
                    </table> : ''}
                    {pageCount > 1 ? <div className={'pagination'}>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={<KeyboardArrowRightIcon/>}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel={<KeyboardArrowLeftIcon/>}
                            renderOnZeroPageCount={null}
                        />
                    </div> : ''}
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}

export default connect(props => props.problems, {getAlLProblems, getAllVerifyResponse, messageReset})(Problems)
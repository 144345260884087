import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";
import {ToastContainer} from "react-toastify";
import {useTranslation} from "react-i18next";

export default function Copyright(props) {
    const {t, i18n} = useTranslation()
    return (
        <Typography className={'copyright'} variant="body2" color="text.secondary" align="center" {...props}>
            <Link color="#0a58ca" href="https://koj.uz/">
                {t('koj')}
            </Link>
            <p>{'Copyright © '}{new Date().getFullYear()}</p>
            <ToastContainer/>
        </Typography>
    );
}
import {configureStore} from '@reduxjs/toolkit'
import {api} from "./middleware/api";
import documents from "./documents"
import problems from "./problems"
import report from "./reports"
import worker from "./workers"
import login from "./logIn"
import notifications from "./notifications"


export default configureStore({
    reducer: {
        documents, login, report, notifications, problems, worker
    },
    middleware: [
        api,
    ]
})
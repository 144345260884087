import Header from "../blocks/header";
import Dashboard from "../blocks/dashboard";
import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Copyright from "../blocks/copyright";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import {Link, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getBranchUsers} from "../store/logIn";
import {useEffect, useState} from "react";

function Regions(props) {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const [branches, setBranches] = useState([])

    useEffect(()=>{
        props.getBranchUsers()
    }, [])

    useEffect(()=>{
        setBranches(props.branch_users)
    }, [props])

    const toBack = () => {
        navigate(-1);
    }

    return (
        <div className="App">
            <div className={'home page'}>
                <Header/>
                <Dashboard/>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}>
                    <Toolbar/>
                    <div className={'main'}>
                        <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={toBack}>
                            {t('back')}
                        </Button>
                        <div className="row">
                            {branches.map((item, index) => <div className="col-lg-2" key={index}>
                                <Link to={'/regions/'+item.id}>
                                    <div className="card">
                                        <div className="card-header">
                                            <LocationOnIcon className={'icon-card'}/>
                                        </div>
                                        <div className="card-body">
                                            <h3 className={'text-center mb-0 text-uppercase'}>{item.user_name}</h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>)}
                        </div>
                    </div>
                    <Copyright/>
                </Box>
            </div>
        </div>
    );
}

export default connect(props => props.login, {getBranchUsers})(Regions)

import {connect, useDispatch} from "react-redux";
import {editSaveWorker, getWorker, messageReset, workerSave} from "../store/workers";
import {getAllVerifyResponse} from "../store/logIn";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import TextField from "@mui/material/TextField";
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {toast} from "react-toastify";
import Header from "../blocks/header";
import Dashboard from "../blocks/dashboard";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Copyright from "../blocks/copyright";
import {useTranslation} from "react-i18next";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function EditWorker(props) {

    const [hasError, setHasError] = useState('');
    const [fullname, setFullname] = useState('');
    const [birth_date, setBirthDate] = useState(new Date("2000-01-01"));
    const [order_number, setOrderNumber] = useState('');
    const [order_date, setOrderDate] = useState(new Date("2000-01-01"));
    const [order_worktime, setOrderWorktime] = useState('');
    const [job, setJob] = useState('');
    const [group, setGroup] = useState('');
    const navigate = useNavigate()
    const toBack = () => {
        navigate(-1);
    }
    const params = useParams();
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token !== 'undefined') {
            const data = {
                "token": JSON.parse(token)
            }
            props.getAllVerifyResponse(data)
        }
        props.getWorker(params.id)
    }, [])

    useEffect(() => {
        if (props.verify === false) {
            navigate("/login/");
        }
        if (props.worker_single) {
            setFullname(props.worker_single.fullname)
            setBirthDate(new Date(props.worker_single.birth_date))
            setOrderNumber(props.worker_single.order_number)
            setOrderDate(new Date(props.worker_single.order_date))
            setOrderWorktime(props.worker_single.order_worktime)
            setJob(props.worker_single.job)
            setGroup(props.worker_single.group)
        }
    }, [props])

    function changeBirthDate(newValue) {
        setBirthDate(newValue)
    }

    function changeOrderDate(newValue) {
        setOrderDate(newValue)
    }

    function saveFormData(e) {
        e.preventDefault()
        if (!group) {
            setHasError(true)
        } else {
            setHasError(false)
        }
        const inpBirthDate = e.target[2].value
        const inpOrderDate = e.target[9].value
        const [day, month, year] = inpBirthDate.split('/');
        const [days, months, years] = inpOrderDate.split('/');
        const result_bd = [year, month, day].join('-');
        const result_od = [years, months, days].join('-');
        const data = {
            "id": params.id,
            "fullname": fullname,
            "birth_date": result_bd,
            "group": parseInt(group),
            "order_number": order_number,
            "order_date": result_od,
            "order_worktime": order_worktime,
            "job": job
        }
        props.editSaveWorker(data)
    }

    useEffect(() => {
        if (props.message === true) {
            navigate('/workers/')
        } else if (props.message === 'error') {
            toast.error(t('save_error'), {theme: 'colored'})
            props.messageReset()
        }
    }, [props.message])


    return <div>
        <div className={'page'}>
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                        <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={toBack}>
                            {t('back')}
                        </Button>
                    <h2 className={'page-title text-center'}>{t('change_info')}</h2>
                    <form className={'add-form'} onSubmit={saveFormData}>
                        <div>
                            <p><span>{t('fullname')}</span><TextField value={fullname}
                                                                      onChange={(e) => setFullname(e.target.value)}
                                                                      id="outlined-basic" label={t('fullname')}
                                                                      variant="outlined" required/></p>
                            <p><span>{t('birth_date')}:</span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        minDate={new Date("1940-01-01")}
                                        maxDate={new Date("2020-01-01")}
                                        label={t('birth_date')}
                                        inputFormat="DD/MM/YYYY"
                                        value={birth_date}
                                        onChange={changeBirthDate}
                                        renderInput={(params) => <TextField required {...params} />}
                                    />
                                </LocalizationProvider>
                            </p>
                        </div>
                        <div>
                            <p><span>{t('group')}:</span>
                                <Box sx={{minWidth: 200}}>
                                    <FormControl fullWidth>
                                        <InputLabel error={hasError} required
                                                    id="demo-simple-select-label">{t('group')}</InputLabel>
                                        <Select
                                            error={hasError}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={group}
                                            label={t('group')}
                                            onChange={(e) => {
                                                setGroup(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={4}>{t('no_group')}</MenuItem>
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box></p>
                        </div>
                        <h3 className={'mt-3 mb-3'}>{t('work_entering')}</h3>
                        <div>
                            <p>
                                <span>{t('order_number')}:</span>
                                <TextField value={order_number} onChange={(e) => setOrderNumber(e.target.value)} required
                                           id="outlined-basic" label={t('order_number')} variant="outlined"/>
                            </p>
                            <p><span>{t('order_date')}:</span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        minDate={new Date("1940-01-01")}
                                        label={t('order_date')}
                                        inputFormat="DD/MM/YYYY"
                                        value={order_date}
                                        onChange={changeOrderDate}
                                        renderInput={(params) => <TextField required {...params} />}
                                    />
                                </LocalizationProvider>
                            </p>
                        </div>
                        <div>
                            <p>
                                <span>{t('order_worktime')}:</span><TextField value={order_worktime}
                                                                              onChange={(e) => setOrderWorktime(e.target.value)}
                                                                              required id="outlined-basic"
                                                                              label={t('order_worktime')}
                                                                              variant="outlined"/>
                            </p>
                            <p>
                                <span>{t('job')}:</span>
                                <TextField value={job} onChange={(e) => setJob(e.target.value)} required
                                           id="outlined-basic" label={t('job')} variant="outlined"/>
                            </p>
                        </div>
                        <Button type={'submit'} variant="contained" endIcon={<AddIcon/>} fullWidth
                                style={{height: '45px'}}>
                            {t('save')}
                        </Button>
                    </form>
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}

export default connect(props => props.worker, {
    workerSave,
    getAllVerifyResponse,
    messageReset,
    editSaveWorker,
    getWorker
})(EditWorker)
import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'report',
    initialState: {reports: [], message: false, report_single: false},
    reducers:{
        getAllReport: (state,action)=>{
            state.reports = action.payload
        },
        getOneReport: (state,action)=>{
            state.report_single = action.payload
        },
        saveReport: (state,action)=>{
            state.reports.unshift(action.payload)
            state.message = true
        },
        editReport: (state,action)=>{
            state.report_single = false
            state.message = true
        },
        deleteToReport: (state,action)=>{
            state.reports = state.reports.filter(item=>item.id !== action.payload.id)
            state.message = 'deleted'
        },
        messageReset: (state,action)=>{
            state.message = false
        },
        messageFail: (state,action)=>{
            state.message = 'error'
        }
    }
})

export const getReports = () => apiCall({
    url: 'reports/',
    method: 'GET',
    onSuccess: slice.actions.getAllReport.type
})

export const reportSave = (data) => apiCall({
    url: 'reports/',
    method: 'POST',
    onSuccess: slice.actions.saveReport.type,
    onFail: slice.actions.messageFail.type,
    data,
})

export const getReport = (id) => apiCall({
    url: 'reports/'+id,
    method: 'GET',
    onSuccess: slice.actions.getOneReport.type,
})

export const editSaveReport = (data) => apiCall({
    url: 'reports/'+data.id+'/',
    method: 'PUT',
    onSuccess: slice.actions.editReport.type,
    onFail: slice.actions.messageFail.type,
    data,
})

export const deleteReport = (data) => apiCall({
    url: 'reports/'+data.id+'/',
    method: 'DELETE',
    onSuccess: slice.actions.deleteToReport.type,
    onFail: slice.actions.messageFail.type,
})

export default slice.reducer
export const {messageReset} = slice.actions

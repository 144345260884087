import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store/store";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css';
import SignIn from "./pages/loginPage";
import './i18n';
import Documents from "./pages/documents";
import Reports from "./pages/reports";
import ReportAdd from "./pages/reportAdd";
import EditReport from "./pages/editReport";
import ViewReport from "./pages/viewReport";
import DocumentAdd from "./pages/documentAdd";
import EditDocument from "./pages/editDocument";
import Main from "./pages/main";
import Regions from "./pages/regions";
import ProblemAdd from "./pages/problemAdd";
import EditProblem from "./pages/editProblem";
import ViewProblem from "./pages/viewProblem";
import Problems from "./pages/problems";
import Workers from "./pages/workers";
import WorkerAdd from "./pages/workerAdd";
import EditWorker from "./pages/editWorker";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Suspense fallback={'Loading...'}>
            <Provider store={store}>
                <Routes>
                    <Route path={'/'} element={<App/>}/>
                    <Route path={'/dashboard/'} element={<App/>}/>
                    <Route path={'/login/'} element={<SignIn/>}/>
                    <Route path={'/documents/'} element={<Documents/>}/>
                    <Route path={'/documents/:id'} element={<Documents/>}/>
                    <Route path={'/reports/'} element={<Reports/>}/>
                    <Route path={'/reports/:id'} element={<Reports/>}/>
                    <Route path={'/problems/'} element={<Problems/>}/>
                    <Route path={'/problems/:id'} element={<Problems/>}/>
                    <Route path={'/main/'} element={<Main/>}/>
                    <Route path={'/regions/'} element={<Regions/>}/>
                    <Route path={'/regions/:id'} element={<Main/>}/>
                    <Route path={'/add-report/'} element={<ReportAdd/>}/>
                    <Route path={'/add-problem/'} element={<ProblemAdd/>}/>
                    <Route path={'/add-document/'} element={<DocumentAdd/>}/>
                    <Route path={'/edit-report/:id'} element={<EditReport/>}/>
                    <Route path={'/edit-problem/:id'} element={<EditProblem/>}/>
                    <Route path={'/edit-document/:id'} element={<EditDocument/>}/>
                    <Route path={'/view-report/:id'} element={<ViewReport/>}/>
                    <Route path={'/view-problem/:id'} element={<ViewProblem/>}/>
                    <Route path={'/workers/'} element={<Workers/>}/>
                    <Route path={'/workers/:id'} element={<Workers/>}/>
                    <Route path={'/add-worker/'} element={<WorkerAdd/>}/>
                    <Route path={'/edit-worker/:id'} element={<EditWorker/>}/>
                </Routes>
            </Provider>
        </Suspense>
    </BrowserRouter>
);

reportWebVitals();

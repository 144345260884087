import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'notifications',
    initialState: {notifications: []},
    reducers:{
        getAllNotf: (state,action)=>{
            state.notifications = action.payload
        },
        editNotf: (state,action)=>{
            state.notifications = state.notifications.filter(item=>item.id !== action.payload.id)
        },
    }
})

export const getNotifications = () => apiCall({
    url: 'notifications/',
    method: 'GET',
    onSuccess: slice.actions.getAllNotf.type
})


export const editSaveNotifications = (data) => apiCall({
    url: 'notifications/'+data.id+'/',
    method: 'PUT',
    onSuccess: slice.actions.editNotf.type,
    data,
})

export default slice.reducer
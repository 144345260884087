import './App.css';
import Header from "./blocks/header";
import Dashboard from "./blocks/dashboard";
import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Copyright from "./blocks/copyright";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DomainIcon from '@mui/icons-material/Domain';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getAllVerifyResponse, getUserInfo} from "./store/logIn";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";


function App(props) {
    const {t, i18n} = useTranslation()

    const [is_main, setIsMain] = useState(false)

    useEffect(()=>{
        props.getUserInfo()
    }, [])

    useEffect(()=>{
        setIsMain(props.user.is_main)
    }, [props])

    return (
        <div className="App">
            <div className={'home page'}>
                <Header/>
                <Dashboard/>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}>
                    <Toolbar/>
                    <div className={'main'}>
                        {is_main
                            ?
                            <div className="row">
                                <div className="col-lg-3">
                                    <Link to={'/main/'}>
                                        <div className="card">
                                            <div className="card-header">
                                                <DomainIcon className={'icon-card'}/>
                                            </div>
                                            <div className="card-body">
                                                <h3 className={'text-center mb-0 text-uppercase'}>{t('main')}</h3>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-3">
                                    <Link to={'/regions/'}>
                                        <div className="card">
                                            <div className="card-header">
                                                <LocationCityIcon className={'icon-card'}/>
                                            </div>
                                            <div className="card-body">
                                                <h3 className={'text-center mb-0 text-uppercase'}>{t('other')}</h3>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-lg-3">
                                    <Link to={'/documents/'}>
                                        <div className="card">
                                            <div className="card-header">
                                                <FilePresentIcon className={'icon-card'}/>
                                            </div>
                                            <div className="card-body">
                                                <h3 className={'text-center mb-0 text-uppercase'}>{t('required_documents')}</h3>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-3">
                                    <Link to={'/problems/'}>
                                        <div className="card">
                                            <div className="card-header">
                                                <SyncProblemIcon className={'icon-card'}/>
                                            </div>
                                            <div className="card-body">
                                                <h3 className={'text-center mb-0 text-uppercase'}>{t('problems')}</h3>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-3">
                                    <Link to={'/workers/'}>
                                        <div className="card">
                                            <div className="card-header">
                                                <FolderSharedIcon className={'icon-card'}/>
                                            </div>
                                            <div className="card-body">
                                                <h3 className={'text-center mb-0 text-uppercase'}>{t('list_employees')}</h3>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-3">
                                    <Link to={'/reports/'}>
                                        <div className="card">
                                            <div className="card-header">
                                                <SummarizeIcon className={'icon-card'}/>
                                            </div>
                                            <div className="card-body">
                                                <h3 className={'text-center mb-0 text-uppercase'}>{t('reports')}</h3>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                    <Copyright/>
                </Box>
            </div>
        </div>
    );
}

export default connect(props=>props.login, {getAllVerifyResponse, getUserInfo})(App)
import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'problems',
    initialState: {problems: [], problem_single: false, message: false},
    reducers:{
        getProblems: (state,action)=>{
            state.problems = action.payload
        },
        getOneProblem: (state,action)=>{
            state.problem_single = action.payload
        },
        saveProblem: (state,action)=>{
            state.problems.unshift(action.payload)
            state.message = true
        },
        editProblem: (state,action)=>{
            state.problem_single = false
            state.message = true
        },
        deleteToProblem: (state,action)=>{
            state.problems = state.problems.filter(item=>item.id !== action.payload.id)
            state.message = 'deleted'
        },
        messageReset: (state,action)=>{
            state.message = false
        },
        messageFail: (state,action)=>{
            state.message = 'error'
        }
    }
})

export const getAlLProblems = () => apiCall({
    url: 'problems/',
    method: 'GET',
    onSuccess: slice.actions.getProblems.type
})

export const problemSave = (data) => apiCall({
    url: 'problems/',
    method: 'POST',
    onSuccess: slice.actions.saveProblem.type,
    onFail: slice.actions.messageFail.type,
    headers: {
        "Content-Type": "multipart/form-data",
    },
    data,
})

export const getProblem = (id) => apiCall({
    url: 'problems/'+id,
    method: 'GET',
    onSuccess: slice.actions.getOneProblem.type,
})

export const editSaveProblem = (data) => apiCall({
    url: 'problems/'+data.id+'/',
    method: 'PUT',
    onSuccess: slice.actions.editProblem.type,
    onFail: slice.actions.messageFail.type,
    headers: {
        "Content-Type": "multipart/form-data",
    },
    data,
})

export const deleteProblem = (data) => apiCall({
    url: 'problems/'+data.id+'/',
    method: 'DELETE',
    onSuccess: slice.actions.deleteToProblem.type,
    onFail: slice.actions.messageFail.type,
})

export default slice.reducer
export const {messageReset} = slice.actions
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import Header from "../blocks/header";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {connect} from "react-redux";
import {messageReset, reportSave} from "../store/reports";
import {getAllVerifyResponse} from "../store/logIn";
import Dashboard from "../blocks/dashboard";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Copyright from "../blocks/copyright";
import {useTranslation} from "react-i18next";
import {DatePicker} from "@mui/x-date-pickers";
import {useNavigate} from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";


function ReportAdd(props) {
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()
    const [report, setReport] = useState('');
    const [hasError, setHasError] = useState(false);
    const [hasError1, setHasError1] = useState(false);
    const [year, setYear] = useState(new Date("2022-01-01"));
    const [period, setPeriod] = useState('');
    const [year_plan, setYearPlan] = useState('');
    const [year_plan_currently, setYearPlanCurrently] = useState('');
    const [year_plan_currently_percent, setYearPlanCurrentlyPercent] = useState('');
    const [year_plan_price, setYearPlanPrice] = useState('');
    const [year_plan_price_percent, setYearPlanPricePercent] = useState('');
    const [new_eployees, setNewEployees] = useState('');
    const [new_eployees_invalid, setNewEployeesInvalid] = useState('');
    const [all_new_eployees, setAllNewEployees] = useState('');
    const [all_new_eployees_invalid, setAllNewEployeesInvalid] = useState('');
    const [new_eployees_price, setNewEployeesPrice] = useState('');
    const [new_eployees_price_invalid, setNewEployeesPriceInvalid] = useState('');
    const [salary_all, setSalaryAll] = useState('');
    const [salary_all_invalid, setSalaryAllInvalid] = useState('');
    const [salary_one, setSalaryOne] = useState('');
    const [salary_one_invalid, setSalaryOneInvalid] = useState('');
    const [products, setProducts] = useState([{title: '', measure: '', amount: '', product_number: ''}]);
    const [isSend, setIsSend] = useState(false);
    const [isAmounts, setIsAmounts] = useState(false);
    const toBack = () => {
        navigate(-1);
    }


    function AddProduct(){
        var my_products = products
        var data = {title: '', measure: '', amount: '', product_number: ''}
        my_products.push(data)
        return setProducts([...my_products])
    }

    function RemoveProduct(){
        var my_products = products
        my_products.pop()
        return setProducts([...my_products])
    }

    function changeTitle(itemIndex, val) {
        setProducts(prevState => prevState.map((item,index) => index === itemIndex ?
            {...item, title: val}
            : item))
    }

    function changeMeasure(itemIndex, val) {
        setProducts(prevState => prevState.map((item,index) => index === itemIndex ?
            {...item, measure: val}
            : item))
    }

    function changeAmount(itemIndex, val) {
        setProducts(prevState => prevState.map((item,index) => index === itemIndex ?
            {...item, amount: val}
            : item))
    }

    function changeProductNumber(itemIndex, val) {
        setProducts(prevState => prevState.map((item,index) => index === itemIndex ?
            {...item, product_number: val}
            : item))
    }

    function saveFormData(e) {
        e.preventDefault()
        let sum_products = 0
        for (let i = 0; i < products.length; i++) {
            sum_products += Number(products[i].amount)
        }
        if (Number(year_plan_currently) !== sum_products){
            setIsAmounts(true)
        } else{
            setIsAmounts(false)
        }
        if (!report) {
            setHasError(true)
        } else {
            setHasError(false)
        }
        if (!period) {
            setHasError1(true)
        } else {
            setHasError1(false)
        }
        if (Number(year_plan_currently) === sum_products){
            const data = {
                "type": report,
                "year": e.target[2].value,
                "month": period,
                "year_plan": year_plan,
                "year_plan_currently": year_plan_currently,
                "year_plan_currently_percent": year_plan_currently_percent,
                "year_plan_price": year_plan_price,
                "year_plan_price_percent": year_plan_price_percent,
                "new_employees_all": new_eployees,
                "new_employees_invalid": new_eployees_invalid,
                "employees_all": all_new_eployees,
                "employees_invalid": all_new_eployees_invalid,
                "all_members": new_eployees_price,
                "all_members_invalid": new_eployees_price_invalid,
                "price_emloyees_all": salary_all,
                "price_emloyees_all_invalid": salary_all_invalid,
                "average_salary": salary_one,
                "average_salary_invalid": salary_one_invalid,
                "products": products,
                "is_send": isSend,
            }
            if (isSend){
                data['status'] = 2
            }
            props.reportSave(data)
        }
    }

    useEffect(()=>{
        if(year_plan_currently && year_plan){
            let myInt = Number(year_plan_currently) / Number(year_plan) * 100
            setYearPlanCurrentlyPercent(Math.round(myInt))
        }else{
            setYearPlanCurrentlyPercent('')
        }
    }, [year_plan, year_plan_currently])

    useEffect(()=>{
        if(new_eployees_price && salary_all){
            let myInt = (Number(salary_all) / Number(new_eployees_price)) / 12
            setSalaryOne(myInt.toFixed(3))
        }else{
            setSalaryOne('')
        }
    }, [salary_all, new_eployees_price])

    useEffect(()=>{
        if(new_eployees_price_invalid && salary_all_invalid){
            let myInt = (Number(salary_all_invalid) / Number(new_eployees_price_invalid)) / 12
            setSalaryOneInvalid(myInt.toFixed(3))
        }else{
            setSalaryOneInvalid('')
        }
    }, [salary_all_invalid, new_eployees_price_invalid])

    useEffect(()=>{
        if(year_plan_price){
            let myInt = (Number(year_plan_price) / 100) * 2
            setYearPlanPricePercent(myInt.toFixed(1))
        }else{
            setYearPlanPricePercent('')
        }
    }, [year_plan_price])

    useEffect(() => {
        if (props.message === true) {
            toast.success(t('save_success'), {theme: 'colored'})
            setReport('')
            setYear(new Date("2022-01-01"))
            setPeriod('')
            setYearPlan('')
            setYearPlanCurrently('')
            setYearPlanCurrentlyPercent('')
            setYearPlanPrice('')
            setYearPlanPricePercent('')
            setNewEployees('')
            setNewEployeesInvalid('')
            setAllNewEployees('')
            setAllNewEployeesInvalid('')
            setNewEployeesPrice('')
            setNewEployeesPriceInvalid('')
            setSalaryAll('')
            setSalaryAllInvalid('')
            setSalaryOne('')
            setSalaryOneInvalid('')
            setProducts([{title: '', measure: '', amount: '', product_number: ''}])
            navigate('/reports/')
        } else if (props.message === 'error') {
            toast.error(t('save_error'), {theme: 'colored'})
            props.messageReset()
        }
    }, [props.message])

    return <div>
        <div className={'page'}>
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                    <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={toBack}>
                        {t('back')}
                    </Button>
                    <h2 className={'page-title text-center'}>{t('add_report')}</h2>
                    <form className={'add-form add-report'} onSubmit={saveFormData}>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>1. {t('report_about')}</h5>
                        </div>
                        <div>
                            <p><span>{t('type')}:</span>
                                <Box sx={{minWidth: 200}}>
                                    <FormControl fullWidth>
                                        <InputLabel error={hasError} required
                                                    id="demo-simple-select-label">{t('type')}</InputLabel>
                                        <Select
                                            error={hasError}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={report}
                                            label={t('type')}
                                            onChange={(e) => {
                                                setReport(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={1}>{t('type_report')}</MenuItem>
                                            <MenuItem value={2}>{t('type_reply_report')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box></p>
                            <p><span>{t('report_year')}:</span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        minDate={new Date("2022-01-01")}
                                        views={['year']}
                                        label={t('report_year')}
                                        value={year}
                                        onChange={(newValue) => {
                                            setYear(newValue);
                                        }}
                                        renderInput={(params) => <TextField required {...params} helperText={null}/>}
                                    />
                                </LocalizationProvider>
                            </p>
                            <p><span>{t('report_month')}:</span>
                                <Box sx={{minWidth: 200}}>
                                    <FormControl fullWidth>
                                        <InputLabel error={hasError1} required
                                                    id="demo-simple-select-label">{t('report_month')}</InputLabel>
                                        <Select
                                            error={hasError1}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={period}
                                            label={t('report_month')}
                                            onChange={(e) => {
                                                setPeriod(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={1}>{t('quarter')}</MenuItem>
                                            <MenuItem value={2}>{t('semi_annual')}</MenuItem>
                                            <MenuItem value={3}>{t('nine_month')}</MenuItem>
                                            <MenuItem value={4}>{t('yearly')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box></p>
                        </div>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>2. {t('report')}</h5>
                        </div>
                        <table className="add-report table table-bordered">
                            <tbody>
                            <tr>
                                <th>{t('year_plan')}</th>
                                <td>
                                    <TextField
                                        label={t('year_plan')}
                                        value={year_plan}
                                        inputProps={{ min: 0 }}
                                        onChange={(e) => setYearPlan(e.target.value)}
                                        required
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('year_plan_currently')}</th>
                                <td>
                                    <TextField
                                        inputProps={{ min: 0 }}
                                        label={t('year_plan_currently')}
                                        value={year_plan_currently}
                                        error={isAmounts}
                                        onChange={(e) => setYearPlanCurrently(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('year_plan_currently_percent')}</th>
                                <td>
                                    <TextField
                                        label={t('year_plan_currently_percent')}
                                        value={year_plan_currently_percent}
                                        disabled
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('year_plan_price')}</th>
                                <td>
                                    <TextField
                                        label={t('year_plan_price')}
                                        inputProps={{ min: 0 }}
                                        value={year_plan_price}
                                        onChange={(e) => setYearPlanPrice(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('year_plan_price_percent')}</th>
                                <td>
                                    <TextField
                                        inputProps={{ min: 0 }}
                                        label={t('year_plan_price_percent')}
                                        value={year_plan_price_percent}
                                        disabled
                                        // onChange={(e)=>setYearPlanPricePercent(e.target.value)}
                                        name="numberformat"
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('new_eployees')}</th>
                                <td><TextField
                                    label={t('new_eployees')}
                                    inputProps={{ min: 0 }}
                                    value={new_eployees}
                                    onChange={(e) => setNewEployees(e.target.value)}
                                    name="numberformat" required
                                    id="formatted-numberformat-input"
                                    type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                    variant="outlined"
                                /></td>
                            </tr>
                            <tr>
                                <th>{t('new_eployees_invalid')}</th>
                                <td>
                                    <TextField
                                        inputProps={{ min: 0 }}
                                        label={t('new_eployees_invalid')}
                                        value={new_eployees_invalid}
                                        onChange={(e) => setNewEployeesInvalid(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('all_new_eployees')}</th>
                                <td>
                                    <TextField
                                        label={t('all_new_eployees')}
                                        inputProps={{ min: 0 }}
                                        value={all_new_eployees}
                                        onChange={(e) => setAllNewEployees(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('all_new_eployees_invalid')}</th>
                                <td>
                                    <TextField
                                        label={t('all_new_eployees_invalid')}
                                        inputProps={{ min: 0 }}
                                        value={all_new_eployees_invalid}
                                        onChange={(e) => setAllNewEployeesInvalid(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('new_eployees_price')}</th>
                                <td>
                                    <TextField
                                        label={t('new_eployees_price')}
                                        inputProps={{ min: 0, max: all_new_eployees }}
                                        value={new_eployees_price}
                                        onChange={(e) => setNewEployeesPrice(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    {t('new_eployees_price_invalid')}
                                </th>
                                <td><TextField
                                    label={t('new_eployees_price_invalid')}
                                    inputProps={{ min: 0, max: all_new_eployees_invalid }}
                                    value={new_eployees_price_invalid}
                                    onChange={(e) => setNewEployeesPriceInvalid(e.target.value)}
                                    name="numberformat" required
                                    id="formatted-numberformat-input"
                                    type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                    variant="outlined"
                                /></td>
                            </tr>
                            <tr>
                                <th>{t('salary_all')}</th>
                                <td>
                                    <TextField
                                        label={t('salary_all')}
                                        value={salary_all}
                                        inputProps={{ min: 0 }}
                                        onChange={(e) => setSalaryAll(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('salary_all_invalid')}</th>
                                <td>
                                    <TextField
                                        inputProps={{ min: 0 }}
                                        label={t('salary_all_invalid')}
                                        value={salary_all_invalid}
                                        onChange={(e) => setSalaryAllInvalid(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('salary_one')}</th>
                                <td>
                                    <TextField
                                        label={t('salary_one')}
                                        inputProps={{ min: 0 }}
                                        value={salary_one}
                                        name="numberformat" required
                                        disabled
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('salary_one_invalid')}</th>
                                <td>
                                    <TextField
                                        label={t('salary_one_invalid')}
                                        value={salary_one_invalid}
                                        inputProps={{ min: 0 }}
                                        disabled
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2} className={'text-center products'}>{t('products')}</th>
                            </tr>
                            </tbody>
                            {products.map((item, index)=>
                                <tbody key={index}>
                                <tr>
                                    <th>{t('product_title')}</th>
                                    <td><TextField
                                        label={t('product_title')}
                                        value={item.title}
                                        onChange={(e) => changeTitle(index, e.target.value)}
                                        name="product_title"
                                        variant="outlined"
                                        required
                                    /></td>
                                </tr>
                                <tr>
                                    <th>{t('product_type')}</th>
                                    <td>
                                        <TextField
                                            label={t('product_type')}
                                            value={item.measure}
                                            onChange={(e) => changeMeasure(index, e.target.value)}
                                            name="product_type"
                                            variant="outlined"
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('product_number')}</th>
                                    <td>
                                        <TextField
                                            label={t('product_number')}
                                            value={item.product_number}
                                            inputProps={{ min: 0 }}
                                            onChange={(e) => changeProductNumber(index, e.target.value)}
                                            variant="outlined"
                                            name="product_number"
                                            id="formatted-numberformat-input"
                                            type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('product_price')}</th>
                                    <td>
                                        <TextField
                                            inputProps={{ min: 0 }}
                                            label={t('product_price')}
                                            value={item.amount}
                                            error={isAmounts}
                                            onChange={(e) => changeAmount(index, e.target.value)}
                                            name="product_price"
                                            id="formatted-numberformat-input"
                                            type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                            variant="outlined"
                                            required
                                            helperText={isAmounts ? t('not_amount') : t('amounts')}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}><hr/></td>
                                </tr>
                                </tbody>
                            )}
                            <tbody className={'add-product-btn'}>
                            <tr>
                                <td colSpan={2}>
                                    <Button onClick={AddProduct} style={{width: '25%'}} variant="contained" fullWidth color={'primary'}
                                    endIcon={<AddIcon/>} title={t('add_product')}/>
                                    {products.length > 1 ?
                                        <Button onClick={RemoveProduct} style={{width: '25%'}} variant="contained" fullWidth color={'error'}
                                                                   endIcon={<RemoveIcon/>} title={t('delete_product')}/> : ''}

                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div>
                        </div>
                        <div>
                        </div>
                        <div className="button-group">
                            <Button type={'submit'} style={{width: '25%'}} variant="contained"
                                    fullWidth color={'info'} onClick={()=>setIsSend(false)}>
                                {t('save')}
                            </Button>
                            <Button type={'submit'} style={{marginLeft: '20px', width: '25%'}} variant="contained"
                                    fullWidth color={'success'} onClick={()=>setIsSend(true)}>
                                {t('send')}
                            </Button>
                        </div>
                    </form>
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}

export default connect(props => props.report, {reportSave, getAllVerifyResponse, messageReset})(ReportAdd)
import {useEffect, useState} from "react";
import Header from "../blocks/header";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import {connect} from "react-redux";
import {editSaveReport, getReport, messageReset} from "../store/reports";
import {getAllVerifyResponse} from "../store/logIn";
import Dashboard from "../blocks/dashboard";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Copyright from "../blocks/copyright";
import {useTranslation} from "react-i18next";
import {DatePicker} from "@mui/x-date-pickers";
import {useNavigate, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";


function ViewReport(props) {
    const {t, i18n} = useTranslation()
    const [report, setReport] = useState('');
    const [hasError, setHasError] = useState(false);
    const [hasError1, setHasError1] = useState(false);
    const [year, setYear] = useState(new Date("2022-01-01"));
    const [period, setPeriod] = useState('');
    const [year_plan, setYearPlan] = useState('');
    const [year_plan_currently, setYearPlanCurrently] = useState('');
    const [year_plan_currently_percent, setYearPlanCurrentlyPercent] = useState('');
    const [year_plan_price, setYearPlanPrice] = useState('');
    const [year_plan_price_percent, setYearPlanPricePercent] = useState('');
    const [new_eployees, setNewEployees] = useState('');
    const [new_eployees_invalid, setNewEployeesInvalid] = useState('');
    const [all_new_eployees, setAllNewEployees] = useState('');
    const [all_new_eployees_invalid, setAllNewEployeesInvalid] = useState('');
    const [new_eployees_price, setNewEployeesPrice] = useState('');
    const [new_eployees_price_invalid, setNewEployeesPriceInvalid] = useState('');
    const [salary_all, setSalaryAll] = useState('');
    const [salary_all_invalid, setSalaryAllInvalid] = useState('');
    const [salary_one, setSalaryOne] = useState('');
    const [salary_one_invalid, setSalaryOneInvalid] = useState('');
    const [products, setProducts] = useState([]);
    const params = useParams()
    const [regionId, setRegionId] = useState(false)
    useEffect(()=>{
        setRegionId(params.id)
    }, [params.id])
    const navigate = useNavigate()
    const toBack = () => {
        navigate(-1);
    }


    useEffect(() => {
        props.getReport(params.id)
    }, [])

    useEffect(() => {
        if (props.report_single.id === parseInt(params.id)) {
            setReport(props.report_single.type)
            setYear(new Date(props.report_single.year))
            setPeriod(props.report_single.month)
            setYearPlan(parseInt(props.report_single.year_plan))
            setYearPlanCurrently(props.report_single.year_plan_currently)
            setYearPlanCurrentlyPercent(props.report_single.year_plan_currently_percent)
            setYearPlanPrice(props.report_single.year_plan_price)
            setYearPlanPricePercent(props.report_single.year_plan_price_percent)
            setNewEployees(props.report_single.new_employees_all)
            setNewEployeesInvalid(props.report_single.new_employees_invalid)
            setAllNewEployees(props.report_single.employees_all)
            setAllNewEployeesInvalid(props.report_single.employees_invalid)
            setNewEployeesPrice(props.report_single.all_members)
            setNewEployeesPriceInvalid(props.report_single.all_members_invalid)
            setSalaryAll(props.report_single.price_emloyees_all)
            setSalaryAllInvalid(props.report_single.price_emloyees_all_invalid)
            setSalaryOne(props.report_single.average_salary)
            setSalaryOneInvalid(props.report_single.average_salary_invalid)
            setProducts(props.report_single.products)
        }
    }, [props.report_single])


    useEffect(()=>{
        if(year_plan_currently && year_plan){
            let myInt = Number(year_plan_currently) / Number(year_plan) * 100
            setYearPlanCurrentlyPercent(Math.round(myInt))
        }
    }, [year_plan, year_plan_currently])

    return <div>
        <div className={'page'}>
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                    <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={toBack}>
                        {t('back')}
                    </Button>
                    <h2 className={'page-title text-center'}>{t('report')}</h2>
                    {props.report_single && props.report_single.comment ?
                        <div className="alert alert-danger" role="alert" style={{maxWidth: '70%'}}>
                            <h4 className="alert-heading">{t('comment')}</h4>
                            <hr/>
                            <p>{props.report_single.comment}</p>
                        </div> : ''
                    }
                    {props.report_single ? <form className={'add-form add-report'}>
                        <div className={'mt-2 d-block'}>
                            <h5 className={'d-block text-uppercase'}>1. {t('report_about')}</h5>
                        </div>
                        <div>
                            <p><span>{t('type')}:</span>
                                <Box sx={{minWidth: 200}}>
                                    <FormControl fullWidth>
                                        <InputLabel error={hasError} required
                                                    id="demo-simple-select-label">{t('type')}</InputLabel>
                                        <Select
                                            error={hasError}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={report}
                                            disabled label={t('type')}
                                            onChange={(e) => {
                                                setReport(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={1}>{t('type_report')}</MenuItem>
                                            <MenuItem value={2}>{t('type_reply_report')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box></p>
                            <p><span>{t('report_year')}:</span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        minDate={new Date("2022-01-01")}
                                        views={['year']}
                                        disabled label={t('report_year')}
                                        value={year}
                                        onChange={(newValue) => {
                                            setYear(newValue);
                                        }}
                                        renderInput={(params) => <TextField required {...params} helperText={null}/>}
                                    />
                                </LocalizationProvider>
                            </p>
                            <p><span>{t('report_month')}:</span>
                                <Box sx={{minWidth: 200}}>
                                    <FormControl fullWidth>
                                        <InputLabel error={hasError1} required
                                                    id="demo-simple-select-label">{t('report_month')}</InputLabel>
                                        <Select
                                            error={hasError1}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={period}
                                            disabled label={t('report_month')}
                                            onChange={(e) => {
                                                setPeriod(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={1}>{t('jan')}</MenuItem>
                                            <MenuItem value={2}>{t('feb')}</MenuItem>
                                            <MenuItem value={3}>{t('mar')}</MenuItem>
                                            <MenuItem value={4}>{t('apr')}</MenuItem>
                                            <MenuItem value={5}>{t('may')}</MenuItem>
                                            <MenuItem value={6}>{t('jun')}</MenuItem>
                                            <MenuItem value={7}>{t('jul')}</MenuItem>
                                            <MenuItem value={8}>{t('aug')}</MenuItem>
                                            <MenuItem value={9}>{t('sep')}</MenuItem>
                                            <MenuItem value={10}>{t('oct')}</MenuItem>
                                            <MenuItem value={11}>{t('nov')}</MenuItem>
                                            <MenuItem value={12}>{t('dec')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box></p>
                        </div>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>2. {t('report')}</h5>
                        </div>
                        <table className="add-report table table-bordered">
                            <tbody>
                            <tr>
                                <th>{t('year_plan')}</th>
                                <td>
                                    <TextField
                                        disabled label={t('year_plan')}
                                        value={year_plan}
                                        onChange={(e) => setYearPlan(e.target.value)}
                                        required
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('year_plan_currently')}</th>
                                <td>
                                    <TextField
                                        disabled label={t('year_plan_currently')}
                                        value={year_plan_currently}
                                        onChange={(e) => setYearPlanCurrently(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('year_plan_currently_percent')}</th>
                                <td>
                                    <TextField
                                        disabled label={t('year_plan_currently_percent')}
                                        value={year_plan_currently_percent}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('year_plan_price')}</th>
                                <td>
                                    <TextField
                                        disabled label={t('year_plan_price')}
                                        value={year_plan_price}
                                        onChange={(e) => setYearPlanPrice(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('year_plan_price_percent')}</th>
                                <td>
                                    <TextField
                                        disabled label={t('year_plan_price_percent')}
                                        value={year_plan_price_percent}
                                        onChange={(e) => setYearPlanPricePercent(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('new_eployees')}</th>
                                <td><TextField
                                    disabled label={t('new_eployees')}
                                    value={new_eployees}
                                    onChange={(e) => setNewEployees(e.target.value)}
                                    name="numberformat" required
                                    id="formatted-numberformat-input"
                                    type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                    variant="outlined"
                                /></td>
                            </tr>
                            <tr>
                                <th>{t('new_eployees_invalid')}</th>
                                <td>
                                    <TextField
                                        disabled label={t('new_eployees_invalid')}
                                        value={new_eployees_invalid}
                                        onChange={(e) => setNewEployeesInvalid(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('all_new_eployees')}</th>
                                <td>
                                    <TextField
                                        disabled label={t('all_new_eployees')}
                                        value={all_new_eployees}
                                        onChange={(e) => setAllNewEployees(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('all_new_eployees_invalid')}</th>
                                <td>
                                    <TextField
                                        disabled label={t('all_new_eployees_invalid')}
                                        value={all_new_eployees_invalid}
                                        onChange={(e) => setAllNewEployeesInvalid(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('new_eployees_price')}</th>
                                <td>
                                    <TextField
                                        disabled label={t('new_eployees_price')}
                                        value={new_eployees_price}
                                        onChange={(e) => setNewEployeesPrice(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    {t('new_eployees_price_invalid')}
                                </th>
                                <td><TextField
                                    disabled label={t('new_eployees_price_invalid')}
                                    value={new_eployees_price_invalid}
                                    onChange={(e) => setNewEployeesPriceInvalid(e.target.value)}
                                    name="numberformat" required
                                    id="formatted-numberformat-input"
                                    type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                    variant="outlined"
                                /></td>
                            </tr>
                            <tr>
                                <th>{t('salary_all')}</th>
                                <td>
                                    <TextField
                                        disabled label={t('salary_all')}
                                        value={salary_all}
                                        onChange={(e) => setSalaryAll(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('salary_all_invalid')}</th>
                                <td>
                                    <TextField
                                        disabled label={t('salary_all_invalid')}
                                        value={salary_all_invalid}
                                        onChange={(e) => setSalaryAllInvalid(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('salary_one')}</th>
                                <td>
                                    <TextField
                                        disabled label={t('salary_one')}
                                        value={salary_one}
                                        onChange={(e) => setSalaryOne(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('salary_one_invalid')}</th>
                                <td>
                                    <TextField
                                        disabled label={t('salary_one_invalid')}
                                        value={salary_one_invalid}
                                        onChange={(e) => setSalaryOneInvalid(e.target.value)}
                                        name="numberformat" required
                                        id="formatted-numberformat-input"
                                        type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        variant="outlined"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2} className={'text-center products'}>{t('products')}</th>
                            </tr>
                            </tbody>
                            {products.map((item, index) =>
                                <tbody key={index}>
                                <tr>
                                    <th>{t('product_title')}</th>
                                    <td><TextField
                                        disabled label={t('product_title')}
                                        value={item.title}
                                        name="product_title"
                                        variant="outlined"
                                        required
                                    /></td>
                                </tr>
                                <tr>
                                    <th>{t('product_type')}</th>
                                    <td>
                                        <TextField
                                            disabled label={t('product_type')}
                                            value={item.measure}
                                            name="product_type"
                                            variant="outlined"
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('product_number')}</th>
                                    <td>
                                        <TextField
                                            disabled label={t('product_number')}
                                            value={item.product_number}
                                            variant="outlined"
                                            name="product_number"
                                            id="formatted-numberformat-input"
                                            type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('product_price')}</th>
                                    <td>
                                        <TextField
                                            disabled label={t('product_price')}
                                            value={item.amount}
                                            name="product_price"
                                            id="formatted-numberformat-input"
                                            type={"number"} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                            variant="outlined"
                                            required
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            )}
                        </table>
                        <div>
                        </div>
                        <div>
                        </div>
                    </form> : ''}
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}

export default connect(props => props.report, {
    getReport,
    editSaveReport,
    getAllVerifyResponse,
    messageReset
})(ViewReport)
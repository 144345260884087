import Header from "../blocks/header";
import Dashboard from "../blocks/dashboard";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Copyright from "../blocks/copyright";
import Box from "@mui/material/Box";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getAllVerifyResponse} from "../store/logIn";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {editSaveProblem, getProblem, messageReset} from "../store/problems";
import {TextareaAutosize} from "@mui/material";

function EditProblem(props) {
    const navigate = useNavigate()
    const params = useParams()
    const {t, i18n} = useTranslation()
    const [regionId, setRegionId] = useState(false)
    useEffect(() => {
        setRegionId(params.id)
    }, [params.id])
    const toBack = () => {
        navigate(-1);
    }
    const [problem_title, setProblemTitle] = useState('')
    const [problem_text, setProblemText] = useState('')

    useEffect(() => {
        props.getProblem(params.id)
    }, [])

    useEffect(() => {
        setProblemTitle(props.problem_single.title)
        setProblemText(props.problem_single.text)
    }, [props.problem_single])

    function saveFormData(e) {
        e.preventDefault()
        const data = {
            "id": params.id,
            "title": problem_title,
            "text": problem_text,
        }
        props.editSaveProblem(data)
    }

    useEffect(() => {
        if (props.message === true) {
            setProblemText('')
            setProblemTitle('')
            navigate('/problems/')
        } else if (props.message === 'error') {
            toast.error(t('save_error'), {theme: 'colored'})
            props.messageReset()
        }
    }, [props.message])

    return <div>
        <div className={'page'}>
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                    <Button variant="outlined" startIcon={<ChevronLeftIcon/>} onClick={toBack}>
                        {t('back')}
                    </Button>
                    <h2 className={'page-title text-center'}>{t('change_info')}</h2>
                    <form className={'add-form add-document'} onSubmit={saveFormData}>
                        <p>
                            <span>{t('problem_title')}:</span>
                            <TextField
                                label={t('problem_title')}
                                value={problem_title}
                                onChange={(e) => setProblemTitle(e.target.value)}
                                required
                                type={"text"}
                                variant="outlined"
                            />
                        </p>
                        <p>
                            <span>{t('problem_text')}:</span>
                            <TextareaAutosize
                                minRows={6}
                                placeholder={t('problem_text')}
                                value={problem_text}
                                label={t('problem_text')}
                                required
                                onChange={(e) => setProblemText(e.target.value)}
                                variant="outlined"
                            />
                        </p>
                        <Button type={'submit'} style={{width: '100%'}} variant="contained"
                                fullWidth color={'info'}>
                            {t('save')}
                        </Button>
                    </form>
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}

export default connect(props => props.problems, {
    getProblem,
    editSaveProblem,
    getAllVerifyResponse,
    messageReset
})(EditProblem)
import * as React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {useState} from "react";
import {getAllVerifyResponse} from "../store/logIn";
import AppBarBlock from "./AppBar";
import DrawerBlock from "./Drawer";
import Header from "./header";

const mdTheme = createTheme();

export default function Dashboard(props) {
    const [open, setOpen] = React.useState(false);
    const [reload, setReload] = useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    if (reload) {
        setReload(false)
        const token = localStorage.getItem('token');
        if (token !== 'undefined') {
            const data = {
                "token": JSON.parse(token)
            }
            getAllVerifyResponse(data)
        }
    }

    return (
        <ThemeProvider theme={mdTheme}>
            <Header/>
            <CssBaseline/>
            <AppBarBlock open={open} toggleDrawer={toggleDrawer}/>
            <DrawerBlock setReload={setReload} open={open} toggleDrawer={toggleDrawer} className={'drawer'}/>
        </ThemeProvider>
    );
}
import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'worker',
    initialState: {workers: [], message: false, worker_single: false},
    reducers:{
        getAllWorker: (state,action)=>{
            state.workers = action.payload
        },
        getOneWorker: (state,action)=>{
            state.worker_single = action.payload
        },
        saveWorker: (state,action)=>{
            state.workers.unshift(action.payload)
            state.message = true
        },
        editWorker: (state,action)=>{
            state.worker_single = action.payload
            state.workers = state.workers.map(item=>item.id === action.payload.id ? action.payload : item)
            state.message = true
        },
        deleteToWorker: (state,action)=>{
            state.workers = state.workers.filter(item=>item.id !== action.payload.id)
            state.message = 'deleted'
        },
        messageReset: (state,action)=>{
            state.message = false
        },
        messageFail: (state,action)=>{
            state.message = 'error'
        }
    }
})

export const getWorkers = () => apiCall({
    url: 'worker/',
    method: 'GET',
    onSuccess: slice.actions.getAllWorker.type
})

export const workerSave = (data) => apiCall({
    url: 'worker/',
    method: 'POST',
    onSuccess: slice.actions.saveWorker.type,
    onFail: slice.actions.messageFail.type,
    data,
})

export const getWorker = (id) => apiCall({
    url: 'worker/'+id,
    method: 'GET',
    onSuccess: slice.actions.getOneWorker.type,
})

export const editSaveWorker = (data) => apiCall({
    url: 'worker/'+data.id+'/',
    method: 'PUT',
    onSuccess: slice.actions.editWorker.type,
    onFail: slice.actions.messageFail.type,
    data,
})

export const deleteWorker = (data) => apiCall({
    url: 'worker/'+data.id+'/',
    method: 'DELETE',
    onSuccess: slice.actions.deleteToWorker.type,
    onFail: slice.actions.messageFail.type,
})

export default slice.reducer
export const {messageReset, getAllWorker} = slice.actions
